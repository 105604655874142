<template>
  <div>
    <t-dropdown variant="smActions">
      <template
        #trigger="{
          mousedownHandler,
          focusHandler,
          blurHandler,
          keydownHandler,
        }"
      >
        <button
          class="text-oDark focus:outline-none"
          aria-label="Task Status Menu"
          aria-haspopup="true"
          @mousedown="mousedownHandler"
          @focus="focusHandler"
          @blur="blurHandler"
          @keydown="keydownHandler"
        >
          <i class="text-sm text-gray-600 fas fa-ellipsis-v" />
        </button>
      </template>

      <template #default="{ hide }">
        <div
          @click="hide()"
          class="py-1 mt-px bg-white rounded-md shadow-md z-200"
        >
          <!-- <t-dropdown-item class="font-medium" @click="executeAction('status')">
            {{ data.is_active === true ? 'Deactivate' : 'Activate' }}
          </t-dropdown-item> -->

          <!-- <t-dropdown-item @click="executeAction('notifyRiders')">
            Notify Riders
          </t-dropdown-item> -->
          <t-dropdown-item @click="executeAction('delete')" variant="danger">
            {{ $t('components.voucherManagement.actions.delete') }}
          </t-dropdown-item>

          <!-- <t-dropdown-item variant="danger" @click="askConfirmation">
            Delete
          </t-dropdown-item> -->
        </div>
      </template>
    </t-dropdown>

    <ConfirmFirst
      :id="`confirmFirst-${data.id}`"
      :key="`confirmFirst-${data.id}`"
      :isLoading="isLoading"
      :title="`Delete Task?`"
      :subtitle="
        `All associated data will be deleted. This action cannot be undone.`
      "
      :criticalityLevel="`medium`"
      :criticalityTitle="`Please Type The Delation Code`"
      :criticalityPassword="`ILOVEOTORIDE`"
      :confirm-button-text="`Delete`"
      :confirm-button-variant="`warning-alt`"
      @confirm="executeAction('delete')"
      @cancel="handleCancellation"
    />
  </div>
</template>

<script>
import { VoucherConfig } from '@/config/VoucherConfig'
export default {
  name: 'VoucherMoreActionsDropdown',
  components: {
    TDropdownItem: () => import('@/components/dropdown/TDropdownItem'),
    ConfirmFirst: () => import('@/components/modals/ConfirmFirst'),
  },
  props: {
    data: {
      type: Object,
      required: false,
      default() {
        return {
          status: false,
        }
      },
    },
  },
  data: () => ({
    isLoading: false,
  }),
  methods: {
    askConfirmation() {
      this.$modal.show(`confirmFirst-${this.data.id}`)
    },
    async executeAction(type) {
      // todo: delete api endpoint & use only one req block to handle all actions
      if (type === 'delete') {
        const endpoint = VoucherConfig.api.delete(this.data.id)

        this.isLoading = true
        await this.$http
          .delete(endpoint)
          .then((res) => {
            console.log('delete', res.data)

            this.$notify({
              group: 'bottomRight',
              type: `success`,
              title: `Success`,
              text: `Voucher has been deleted successfully`,
            })
            this.$emit('refresh')
            this.$emit('delete:success', res.data)
            this.$modal.hide(`confirmFirst-${this.data.id}`)
          })
          .catch((err) => {
            console.error('v-catch', { err })

            this.$notify(
              {
                group: 'bottomRight',
                type: 'error',
                title: `Error Occurred [${err.response.status}]`,
                text: err.response.data.message ?? 'Failed to delete.',
              },
              5000
            )

            this.$emit('delete:failure', err)
          })
          .finally(() => {
            this.isLoading = false
          })
        return
      }

      if (type === 'notifyRiders') {
        this.$emit('open')
      }
    },
    handleCancellation() {
      console.log('cancelled')
    },
  },
}
</script>

<style lang="scss" scoped></style>
